import React, {Component} from 'react'

class TableHead extends Component {
    render() {
        return (
            <thead className='TableHead'>
            <tr>
                <th>#</th>
                <th>File name</th>
                <th>Time upload</th>
                {/* <th>Carrier</th> */}
                <th>Status</th>
                <th className="Action">Action</th>
            </tr>
            </thead>
        )
    }
}

export default TableHead