import api from './../api'
import buildQuery from "../../helpers/query/buildQuery"

const baseUrl = ''
export const putInvoiceFile = (payoad) => {
    return api.makeAuthRequest({
        url: `${baseUrl}/invoice-export-requests`,
        method: 'POST',
        data: payoad
    })
}

export const downloadTemplate = () => {
    const baseUrl = api.getBaseURL()

    return `${baseUrl}/invoice-export-requests-template-v2.csv`
}

export const listRequestInvoice = (params) => {
    const query = buildQuery(params)
    return api.makeAuthRequest({
        url: `${baseUrl}/invoice-export-requests?${query}`,
        method: 'GET'
    })
}

export const downloadResult = (requestId) => {
    const baseUrl = api.getBaseURL()

    return `${baseUrl}/invoice-export-result/${requestId}`
}